import Tooltip from '@/app/_ui/common/Tooltip';
import DynamicSvgIcon from '@/app/_ui/icons/DynamicSvgIcon';
import { PrimeLogoAnimated } from '@/app/_ui/primeLogoAnimated';
import { type IconType } from '@/app/_ui/types';
import clsx from 'clsx';
import {
  useRef,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from 'react';

export type AppButtonProps = {
  text?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  border?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  data?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'outlined' | 'plain';
  color?: 'error' | 'warning' | 'success' | 'white' | 'transparent';
  borderType?: 'squared' | 'round';
  Icon?: IconType;
  iconSize?: number;
  iconPlacement?: 'left' | 'right';
  padding?: boolean;
  compact?: boolean;
  fullWidth?: boolean;
  forceInline?: boolean;
  isSelected?: boolean;
  children?: ReactNode;
  tooltip?: string | ReactElement;
};

const AppButton = ({
  text,
  onClick,
  isLoading,
  Icon,
  className,
  color,
  children,
  data,
  border,
  borderType = 'round',
  iconSize = 24,
  type = 'button',
  compact = false,
  variant = 'outlined',
  disabled = false,
  iconPlacement = 'right',
  padding = true,
  fullWidth = false,
  forceInline = false,
  isSelected = false,
  tooltip,
}: AppButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isDisabled =
    (disabled || (onClick == null && type !== 'submit') || isLoading) ?? false;
  let buttonColorClass = null;
  let buttonColorHoverClass = null;
  let textHoverColorClass = null;
  let borderColorClass = null;
  let selectedClass = null;
  let textClass = null;
  switch (color) {
    case 'error':
      buttonColorClass = variant === 'primary' && 'bg-red-700';
      buttonColorHoverClass = 'hover:bg-red-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-red-300' : 'hover:text-white';
      borderColorClass = 'border-red-600';
      selectedClass = 'bg-red-500 hover:bg-red-500';
      textClass = 'text-white';
      break;
    case 'success':
      buttonColorClass = variant === 'primary' && 'bg-green-700';
      buttonColorHoverClass = 'hover:bg-green-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-green-300' : 'hover:text-white';
      borderColorClass = 'border-green-600';
      selectedClass = 'bg-green-500 hover:bg-green-500';
      textClass = 'text-white';
      break;
    case 'warning':
      buttonColorClass = variant === 'primary' && 'bg-orange-700';
      buttonColorHoverClass = 'hover:bg-orange-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-orange-300' : 'hover:text-white';
      borderColorClass = 'border-orange-600';
      selectedClass = 'bg-orange-500 hover:bg-orange-500';
      textClass = 'text-white';
      break;
    case 'white':
      buttonColorClass = variant === 'primary' && 'bg-gray-50 text-slate-800';
      buttonColorHoverClass =
        variant === 'primary' ? 'hover:bg-gray-100' : 'hover:bg-gray-600';
      textHoverColorClass = variant === 'plain' ? 'hover:text-gray-100' : null;
      borderColorClass = 'border-gray-300 hover:border-gray-100';
      selectedClass = 'bg-gray-50 hover:bg-gray-50 text-slate-800 border-white';
      textClass =
        variant === 'primary'
          ? 'text-slate-800 hover:text-white'
          : 'text-white';
      break;
    case 'transparent':
      buttonColorClass = 'bg-transparent';
      borderColorClass = 'border-white';
      textClass = 'text-white';
      break;
    default:
      buttonColorClass = variant === 'primary' && 'bg-purple-700';
      buttonColorHoverClass = 'hover:bg-purple-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-purple-300' : 'hover:text-white';
      borderColorClass = 'border-purple-600';
      selectedClass = 'bg-purple-500 hover:bg-purple-500';
      textClass = 'text-white';
  }

  const shouldHideBorder = variant === 'plain' || border === false;

  const innerContainerClass = clsx(
    'gap-2 inline-flex items-center justify-center',
    disabled && 'opacity-50',
  );

  const buttonClass = clsx(
    borderType === 'squared' ? 'rounded-none' : 'rounded-lg',
    `rounded-lg shadow-lg transition duration-200`,
    padding && 'px-4 py-2',
    !shouldHideBorder && 'border-2',
    variant === 'plain' && 'bg-transparent',
    isSelected && selectedClass,
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    compact ? 'h-6' : 'h-10',
    'inline-flex items-center justify-center',
    fullWidth && 'w-full',
    forceInline && 'whitespace-nowrap break-keep',
    textClass,
    buttonColorClass,
    buttonColorHoverClass,
    textHoverColorClass,
    borderColorClass,
    className,
  );

  const IconElement = Icon ? (
    typeof Icon === 'string' ? (
      <DynamicSvgIcon iconName={Icon} height={iconSize} width={iconSize} />
    ) : (
      <Icon
        className={`h-${Math.ceil(iconSize / 6)} w-${Math.ceil(iconSize / 6)}`}
      />
    )
  ) : null;

  const buttonContent = (
    <div className={innerContainerClass}>
      {iconPlacement === 'left' && IconElement}
      {children ? children : text}
      {iconPlacement === 'right' && IconElement}
    </div>
  );

  return (
    <button
      type={type}
      onClick={onClick}
      className={buttonClass}
      disabled={isDisabled}
      data-data={data}
      ref={buttonRef}
    >
      {isLoading ? (
        <PrimeLogoAnimated type="onlyLogo" height={30} width={30} />
      ) : tooltip ? (
        <Tooltip content={tooltip}>{buttonContent}</Tooltip>
      ) : (
        buttonContent
      )}
    </button>
  );
};

export default AppButton;
